<template>
  <div class="app-container">
    <!-- 面包屑 -->
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }" replace>
          <el-icon name="arrow-left" /> 返回</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>

    <div class="main">
      <section>
        <div class="title">公司介绍</div>
        <p>
          北京数音未来科技有限公司是中国新一代数字音乐交互服务提供商，公司位于北京市朝阳区阜通东大街1号院5号楼2单元8层。数音未来是互联网技术创新的新生代数字音乐企业，致力于为互联网用户和数字音乐产业发展提供完善的解决方案。PlayList是北京数音未来科技有限公司旗下的正版素材网站。自公司创建以来，一直在数字音乐发展上大胆尝试，与大量唱片公司、版权管理机构合作探索发展思路，积累了海量的数字版权，并在推动更广范围内的跨行业、跨平台合作上做出努力，在艰巨的全球音乐数字化进程中作出自身的贡献。
        </p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style lang="scss" scoped>
.app-container {
  width: 1320px;
  margin: 0 auto;
  padding-top: 70px;
  min-height: calc(100vh - 268px);
  padding-bottom: 80px;
  .breadcrumb {
    width: 1200px;
    padding: 40px 0;
    /deep/ {
      .el-breadcrumb__inner {
        @include font-bold($size: 20px, $color: rgba(51, 51, 51, 0.6));
        line-height: 28px;
        cursor: pointer;
      }
      .el-breadcrumb__separator {
        @include font-bold(
          $size: 18px,
          $color: rgba(51, 51, 51, 0.6),
          $weight: bold
        );
      }
    }
  }
  .main {
    min-height: 420px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(33, 48, 83, 0.07);
    border-radius: 12px;
    padding: 38px 50px;
    section {
      font-size: 18px;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.8);
      line-height: 28px;
      .title {
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
